function dataLoad( zone ){
    $.ajax({
        type: "POST",
        url: "/ajax",
        data: {
           zoneId: zone
        },
        dataType: "json",
        success: function(response) {
            $('.metresRestants').text( response.metresRestant )
            $('.plansrc').attr('src', response.plan )
            $('.photosrc').attr('src', response.photo )
        }
    });
}


$( document ).ready(function() {
        dataLoad($('#inscription_ZoneId').val());

    $('#inscription_ZoneId').on("change", function(){   
        dataLoad($(this).val())
    });
});



    // var value = $('input[type="radio"]:checked').val();
    // var path = "{{ path('gab_front_inscription_ajax') }}";
    // $.ajax({
    //     url: path,
    //     type: 'POST',
    //     data: {idValue: value},
    //     dataType: 'JSON',
    // })
    // .success(function(data) {
    //     $('#reservations_metres').attr('max',data.data).attr('min',1);
    //     $( ".dispo" ).text( data.data );
    // })
